export interface Book {
  year: number
  title: string
  img: string
  link: string
  rating?: number
}

const books: Book[] = [
  {
    title: "The Book Thief",
    year: 2015,
    img: "https://productimages.worldofbooks.com/0552773891.jpg",
    link: "https://amzn.to/3qhXp8k",
  },
  {
    title: "A Game of Thrones",
    year: 2015,
    link: "https://amzn.to/3pXhjpb",
    img: "https://m.media-amazon.com/images/I/51NF6gOqnuL._SY346_.jpg",
  },
  {
    title: "A Clash of Kings",
    year: 2015,
    link: "https://amzn.to/3CWPv80",
    img: "https://m.media-amazon.com/images/I/51SY86tpEIL._SY346_.jpg",
  },
  {
    title: "A Storm of Swords",
    year: 2015,
    link: "https://amzn.to/3bTTWEJ",
    img: "https://m.media-amazon.com/images/I/516caGGd+CL.jpg",
  },
  {
    title: "A Feast for Crows",
    year: 2015,
    link: "https://amzn.to/3mU9BKz",
    img: "https://m.media-amazon.com/images/I/51bIC-YQRfL._SY346_.jpg",
  },
  {
    title: "A Dance with Dragons",
    year: 2015,
    link: "https://amzn.to/3BWyU2T",
    img: "https://m.media-amazon.com/images/I/51b8g+8ISJL._SY346_.jpg",
  },
  {
    title: "A Knight of the Seven Kingdoms",
    year: 2015,
    link: "https://amzn.to/3qhVwsn",
    img: "https://m.media-amazon.com/images/I/51SYvkSevfL._SY346_.jpg",
  },
  {
    title: "Oranges Are Not the Only Fruit",
    year: 2015,
    link: "https://amzn.to/3bRX57S",
    img: "https://m.media-amazon.com/images/I/5145Kjbn0XL._SY346_.jpg",
  },

  {
    title: "So You've Been Publicly Shamed",
    year: 2015,
    link: "https://amzn.to/3BQh8y1",
    img:
      "https://m.media-amazon.com/images/I/71cvRhGR2zL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "Lord of the Flies",
    year: 2015,
    link: "https://amzn.to/3BQh8y1",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51NjXPoPKtL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Picture of Dorian Grey",
    year: 2015,
    link: "https://amzn.to/3bRXlUo",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41-cEvn5QmS._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Stoner",
    year: 2015,
    link: "https://amzn.to/3o54AhD",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51MEdow-jCL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },

  {
    title: "A Brief History of Seven Killings",
    year: 2015,
    link: "https://amzn.to/3bQPhDr",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/516GDs5XKCL._SX323_BO1,204,203,200_.jpg",
  },
  {
    title: "You Can Beat Your Brain",
    year: 2015,
    link: "https://amzn.to/3D7JBkg",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/51C-9Gb7lTL._SX323_BO1,204,203,200_.jpg",
  },
  {
    title: "The Man in the High Castle",
    link: "https://amzn.to/3wqXebY",
    year: 2016,
    img:
      "https://images-na.ssl-images-amazon.com/images/I/51v49sOhjZL._SX324_BO1,204,203,200_.jpg",
  },
  {
    title: "Wars of the Roses: Stormbird",
    year: 2016,
    link: "https://amzn.to/3CVMDbB",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/51OW+LVU8uL._SY344_BO1,204,203,200_.jpg",
  },
  {
    title: "Wars of the Roses: Trinity",
    year: 2016,
    link: "https://amzn.to/2ZVYNm1",
    img:
      "https://m.media-amazon.com/images/I/51-bSugysKL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "Norwegian Wood",
    year: 2016,
    link: "https://amzn.to/3H3YlD8",
    img: "https://m.media-amazon.com/images/I/4141KWWcdLL._SY346_.jpg",
  },
  {
    title: "Snuff",
    year: 2016,
    link: "https://amzn.to/30cQq5X",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51J8dhfDKPL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Unbearable Lightness of Being",
    year: 2016,
    link: "https://amzn.to/3wqdQjX",
    img:
      "https://m.media-amazon.com/images/I/411uQYFgNGL._AC_UY436_FMwebp_QL65_.jpg",
  },
  {
    title: "The Wind Up Bird Chronicle",
    year: 2016,
    link: "https://amzn.to/3EPJlqG",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/4133S7mCthL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "A Wild Sheep Chase",
    year: 2016,
    link: "https://amzn.to/3kjnv7j",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/41p8UKWGb4L._SX323_BO1,204,203,200_.jpg",
  },
  {
    title: "Code",
    year: 2016,
    link: "https://amzn.to/3mUBVfS",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/31RLovAad7L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Kite Runner",
    year: 2016,
    link: "https://amzn.to/3CZmxUQ",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/31E58CphgRL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Power",
    year: 2017,
    link: "https://amzn.to/3mQRrcy",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/517NCwynp2L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Do Androids Dream of Electric Sheep",
    year: 2017,
    link: "https://amzn.to/3qdGuUx",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51Cf9NJizHL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Curious Incident of the Dog in the Night Time",
    year: 2017,
    link: "https://amzn.to/3EPJV7Q",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51fZV848tOL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Buried Giant",
    year: 2017,
    link: "https://amzn.to/306NVSE",
    img:
      "https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1470255455l/24553083.jpg",
  },
  {
    title: "The Trial",
    year: 2017,
    link: "https://amzn.to/3qwyluH",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/31o087+ohuL._SX324_BO1,204,203,200_.jpg",
  },
  {
    title: "Autumn",
    year: 2017,
    link: "https://amzn.to/3khJ6gm",
    img: "https://images-na.ssl-images-amazon.com/images/I/A17O+SujVzL.jpg",
  },
  {
    title: "Frankenstein",
    year: 2017,
    link: "https://amzn.to/3o7bumu",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/413rXe+d91L._SY344_BO1,204,203,200_.jpg",
  },
  {
    title: "Deep Work",
    year: 2017,
    link: "https://amzn.to/3kjo80F",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/41W+SUER72L._SX333_BO1,204,203,200_.jpg",
  },
  {
    title: "The Odyssey",
    year: 2017,
    link: "https://amzn.to/3CQNg65",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51HGgJJMTqL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Ted Talks: The Official Guide to Publicly Speaking",
    year: 2017,
    link: "https://amzn.to/3BPaKqU",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41MdWBp7tfL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Keeping an Eye Open",
    year: 2018,
    link: "https://amzn.to/3bNBWvL",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51eeC4dItqL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Post Office",
    year: 2018,
    link: "https://amzn.to/3mRG4RI",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51QQlmIw8fL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Remains of the Day",
    year: 2018,
    link: "https://amzn.to/309kgIG",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41698UgoMPL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Nocturnes",
    year: 2018,
    link: "https://amzn.to/3qdH9oZ",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41Iq5IIas7L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "One Day in the Life of Ivan Denisovich",
    year: 2018,
    link: "https://amzn.to/3H508bs",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51MuGFYyOKL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Hobbit",
    year: 2018,
    link: "https://amzn.to/3oeHcOS",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/419UGp1CsQL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Devops Handbook",
    year: 2018,
    link: "https://amzn.to/2YqHZmR",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51v2ZHygJUL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Book of Tokyo: A City in Short Fiction",
    link: "https://amzn.to/3H1NoSM",
    year: 2018,
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51Qi3Myrq0L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Zero to One",
    year: 2018,
    link: "https://amzn.to/3BTgK20",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41XuzXCGLlL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Lean Startup",
    year: 2018,
    link: "https://amzn.to/304qTvy",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51hEcQMCxwL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "A Little Life",
    year: 2018,
    link: "https://amzn.to/3H16jx3",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51F0aKax9oL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Julius Ceaser",
    year: 2018,
    link: "https://amzn.to/3BUUNj0",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41xA1alIv4L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "1984",
    year: 2018,
    link: "https://amzn.to/30715PH",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51J5c7-uycL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Winter",
    year: 2018,
    link: "https://amzn.to/3mWo5JC",
    img: "https://productimages.worldofbooks.com/0241973333.jpg",
  },
  {
    title: "Overcoming Gravity",
    year: 2019,
    link: "https://amzn.to/3c6TKlL",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51aFolgwN8L._SX218_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Uzumaki",
    year: 2019,
    link: "https://amzn.to/2ZZr4Io",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51Ef1f4UnqL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Prisoners of Geography",
    year: 2019,
    link: "https://amzn.to/3CYTp01",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51dYH-gcQ7L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "My Year of Rest and Relaxation",
    year: 2019,
    link: "https://amzn.to/3D0K5sA",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/510Vub5ZLRL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "An Artist of the Floating World",
    year: 2019,
    link: "https://amzn.to/3H3Mmpt",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51gBPyuK7nL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Radical Candor",
    year: 2019,
    link: "https://amzn.to/3EPQLtW",
    img: "https://m.media-amazon.com/images/I/51wSyDsdnPL._SY346_.jpg",
  },
  {
    title: "Circe",
    year: 2019,
    link: "https://amzn.to/2YtCvYw",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51JsGN-JrYL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Hard Thing About Hard Things",
    year: 2019,
    link: "https://amzn.to/31LkMND",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51NCFyHpkxL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Crossing the Chasm",
    year: 2019,
    link: "https://amzn.to/3o1NcKA",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41AJKOyiGML._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Five Dysfunctions of a Team",
    year: 2019,
    link: "https://amzn.to/3D2T9gM",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51QArmq8raL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Radical Focus",
    year: 2019,
    link: "https://amzn.to/3CX4zT8",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41B51e3idLS._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Invisible Women",
    year: 2019,
    link: "https://amzn.to/3CYgY98",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51fv0DvC-FL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Microcopy: The Complete Guide",
    year: 2019,
    link: "https://www.microcopybook.com/",
    img: "https://images-na.ssl-images-amazon.com/images/I/71XdNdBB40L.jpg",
  },
  {
    title: "Find Me",
    year: 2019,
    link: "https://amzn.to/3H18fWl",
    img: "https://m.media-amazon.com/images/I/51gek0bizpL._SY346_.jpg",
  },
  {
    title: "Spring",
    year: 2019,
    link: "https://amzn.to/3qdJPTz",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/512TaU9OXnL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Mythos",
    year: 2019,
    link: "https://amzn.to/3qio3ya",
    img: "https://m.media-amazon.com/images/I/51kFflPAhHL._SY346_.jpg",
  },

  {
    title: "When Breath Becomes Air",
    year: 2020,
    link: "https://amzn.to/3bSRle0",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41SCBhKldyL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Before the Coffee Gets Cold",
    year: 2020,
    link: "https://amzn.to/31I5Kbl",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/41tCX+c2iAL._SX328_BO1,204,203,200_.jpg",
  },
  {
    title: "The Design of Everyday Things",
    year: 2020,
    link: "https://amzn.to/3kdmldd",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/410RTQezHYL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Sense and Respond",
    year: 2020,
    link: "https://amzn.to/3qhFChz",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41-KZOIVsCL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Fringes: Life on the Edge of Professional Rugby",
    year: 2020,
    link: "https://amzn.to/3qg24aU",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/412HL8jROfL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Axiom's End",
    year: 2020,
    link: "https://amzn.to/3ES4dOb",
    img: "https://m.media-amazon.com/images/I/514wPzi8b8L._SY346_.jpg",
  },
  {
    title: "Don't Make me Think",
    year: 2020,
    link: "https://amzn.to/3kgky7u",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41MdP5Tn0wL._SX218_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Measure What Matters",
    year: 2020,
    link: "https://amzn.to/3qedrjG",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41pP76e12vL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Advantage",
    year: 2020,
    link: "https://amzn.to/3H13nRa",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41evapq032L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "How to Win Friends and Influence People",
    year: 2020,
    link: "https://amzn.to/31HL43a",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51EV3zDJz2L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Delivering Happiness",
    year: 2020,
    link: "https://amzn.to/3kj9IgV",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41z68nS1WFS._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "On Becoming a Person",
    year: 2020,
    link: "https://amzn.to/3kBCh9J",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41myCeIt44L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "A Confederacy of Dunces",
    year: 2020,
    link: "https://amzn.to/3kgOctn",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51ccunojmjL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Body",
    year: 2020,
    link: "https://amzn.to/3obQrPs",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/51DzlO2ry4L._SX323_BO1,204,203,200_.jpg",
  },
  {
    title: "Atomic Design",
    year: 2021,
    link: "https://atomicdesign.bradfrost.com/",
    img: "https://atomicdesign.bradfrost.com/images/book-cover.svg",
  },
  {
    title: "Feature Management with LaunchDarkly",
    year: 2021,
    link: "https://amzn.to/3Fux0sb",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41Xjjo1LHsL._SX218_BO1,204,203,200_QL40_ML2_.jpg",
    rating: 4,
  },
  {
    title: "Brave New World",
    year: 2021,
    link: "https://amzn.to/3mJmA10",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/51qUjJFVSpL._SX323_BO1,204,203,200_.jpg",
    rating: 4,
  },
  {
    title: "Miyazakiworld",
    year: 2021,
    link: "https://amzn.to/3ES501B",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/517UTliE+YL._SX388_BO1,204,203,200_.jpg",
    rating: 4,
  },
  {
    title: "The Manager's Path",
    year: 2021,
    link: "https://amzn.to/3080TQc",
    img: "https://learning.oreilly.com/library/cover/9781491973882/250w/",
  },
  {
    title: "Press Reset",
    year: 2021,
    link: "https://amzn.to/302nBcD",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41C46AgOctL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Girl at the Lion D'or",
    year: 2021,
    link: "https://amzn.to/31ef7PB",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51f4OGW6kaL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Accessibility for Everyone",
    year: 2021,
    link: "https://abookapart.com/products/accessibility-for-everyone",
    img:
      "https://cdn.shopify.com/s/files/1/0051/7692/products/aba-cover-23_112x@3x.png?v=1568975374",
  },
  {
    title: "Practical Web Inclusion and Accessibility",
    year: 2021,
    link: "https://amzn.to/3GUk8gC",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/31yzcJ-SRDL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },

  {
    title: "Atomic Habits",
    year: 2021,
    link: "https://amzn.to/3kBCV79",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51-nXsSRfZL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Laws of UX",
    year: 2021,
    link: "https://amzn.to/3H3PxNV",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/410ZGx1mcUL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
    rating: 3,
  },
  {
    title: "Scott Pilgrim vs the World",
    year: 2021,
    link: "https://amzn.to/30blEdR",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51-dig1mwFL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Managing Humans",
    year: 2021,
    link: "https://amzn.to/3H3e99u",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/41N84KRQNSL._SX328_BO1,204,203,200_.jpg",
  },
  {
    title: "Lean UX",
    year: 2021,
    link: "https://amzn.to/3bT6xI5",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/41-OunyepEL._SX329_BO1,204,203,200_.jpg",
  },
  {
    title: "Rocket Surgery Made Easy",
    year: 2021,
    link: "https://amzn.to/3o7oZlZ",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/516qy8xvO7L._SX218_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "The Unicorn Project",
    year: 2021,
    link: "https://amzn.to/3mTSY1k",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51A4T36jisL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Grit",
    year: 2021,
    link: "https://amzn.to/3qdOkgV",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51M2BX77-YL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
    rating: 4,
  },
  {
    title: "Indistractible",
    year: 2021,
    link: "https://amzn.to/3wr5VTt",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41zXk9etE2L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
  },
  {
    title: "Inclusive Components",
    year: 2021,
    link: "https://inclusive-components.design/",
    img:
      "https://inclusive-components.design/assets/images/on-light.svg?v=62e4fa65bf",
    rating: 4.5,
  },
  {
    title: "Factfulness",
    year: 2021,
    link: "https://amzn.to/3oc5WXV",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/51t96Z5LT1L._SY291_BO1,204,203,200_QL40_ML2_.jpg",
    rating: 4.5,
  },
  {
    title: "Typescript in 50 Lessons",
    year: 2021,
    link: "https://amzn.to/303UNR0",
    img: "https://m.media-amazon.com/images/I/511GjrsK-pL._SX260_.jpg",
    rating: 4.5,
  },
  {
    title: "Practical Pair Programming",
    year: 2021,
    link: "https://abookapart.com/products/practical-pair-programming",
    img:
      "https://cdn.shopify.com/s/files/1/0051/7692/products/aba-briefs-cover-10_160x@3x.png?v=1594738234",
    rating: 2,
  },
  {
    title: "Augustus",
    year: 2021,
    link: "https://amzn.to/3FbeFR9",
    img: "https://m.media-amazon.com/images/I/51TvHiPj37L._SY346_.jpg",
  },
  {
    title: "The Powers of Geography",
    year: 2021,
    link: "https://amzn.to/3CVlTrB",
    img: "https://m.media-amazon.com/images/I/516GuR27m9L._SY346_.jpg",
    rating: 4.5,
  },
  {
    title: "The Darkening Age",
    year: 2021,
    link: "https://amzn.to/3BPM2Xz",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/61YM28+JZ7L._SY344_BO1,204,203,200_.jpg",
    rating: 2,
  },
  {
    title: "The Making of a Manager",
    year: 2022,
    link: "https://amzn.to/3BjNbJI",
    img: "https://images-na.ssl-images-amazon.com/images/I/71qwuYqlexL.jpg",
    rating: 4,
  },
  {
    title: "Shape Up",
    year: 2022,
    link: "https://basecamp.com/shapeup",
    img: "https://basecamp.com/assets/books/shapeup-cover-black-2ad6153d1627246e9e93cd19f08fe94ca9d75fc09c17951ac33f7bf955a74e59.svg",
    rating: 4,
  },
  {
    title: "Japan: A Short History",
    year: 2022,
    link: "https://amzn.to/3Qorcq5",
    img: "https://images-na.ssl-images-amazon.com/images/I/71R9WyPOHZL.jpg",
    rating: 4,
  },
  {
    title: "Every Layout",
    year: 2022,
    link: "https://every-layout.dev/",
    img: "https://every-layout.dev/images/cover_3rd.svg",
    rating: 5,
  },
  {
    title: "Brotopia",
    year: 2022,
    link: "https://amzn.to/3vAXyWL",
    img:
      "https://m.media-amazon.com/images/P/0525540172.01._SCLZZZZZZZ_SX500_.jpg",
    rating: 4.5,
  },
  {
    title: "Men Who Hate Women",
    year: 2022,
    link: "https://amzn.to/378IMgt",
    img:
      "https://m.media-amazon.com/images/P/172823624X.01._SCLZZZZZZZ_SX500_.jpg",
    rating: 4.5,
  },
  {
    title: "The Psychology of Money",
    year: 2022,
    link: "https://amzn.to/3HzQi0G",
    img:
      "https://images-eu.ssl-images-amazon.com/images/I/41YAXjitzxL._SY291_BO1,204,203,200_QL40_ML2_.jpg",
    rating: 4,
  },
  {
    title: "Explaining Humans",
    year: 2022,
    link: "https://amzn.to/39pfvzt",
    img:
      "https://m.media-amazon.com/images/P/B07VMPTB3K.01._SCLZZZZZZZ_SX500_.jpg",
    rating: 2,
  },
  {
    title: "Call Me By Your Name",
    year: 2022,
    link: "https://amzn.to/3JVq0aW",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/5140KjKykfL._SX324_BO1,204,203,200_.jpg",
    rating: 5,
  },
  {
    title: "Never Split the Difference",
    year: 2022,
    link: "https://amzn.to/3fOfZ1k",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/51jLNhGsl-L._SX323_BO1,204,203,200_.jpg",
    rating: 3.5,
  },
  {
    title: "Ariadne",
    year: 2022,
    link: "https://amzn.to/3Ifhd1K",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/61aT4diGXfL._SX324_BO1,204,203,200_.jpg",
    rating: 2.5,
  },
  {
    title: "The Age of Surveillance Capitalism",
    year: 2022,
    link: "https://amzn.to/3MJMNId",
    img:
      "https://images-na.ssl-images-amazon.com/images/I/41nfe-f7Q3L._SX324_BO1,204,203,200_.jpg",
    rating: 4,
  },
]

export default books
