import React from "react"
import books, { Book } from "../../data/books"
import * as styles from "./bookshelf.module.css"

const booksByYear: Record<number, Array<Book>> = {}

books.forEach(book => {
  if (!booksByYear[book.year]) {
    booksByYear[book.year] = []
  }

  booksByYear[book.year].push(book)
})

const years = Object.keys(booksByYear).sort((a, b) => Number(b) - Number(a))

export function Bookshelf() {
  return (
    <div className={styles.wrapper}>
      {years.map(year => {
        return (
          <div key={year} className={styles.yearList}>
            <h2>{year}</h2>
            <ul className={styles.bookList}>
              {booksByYear[year].map((book: Book) => {
                return (
                  <li key={book.title}>
                    <a rel="noopener noreferrer" href={book.link}>
                      <img loading="lazy" src={book.img} alt={book.title} />
                      <p>{book.title}</p>
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
        )
      })}
    </div>
  )
}
