import React from "react"
import { Bookshelf } from "../components/bookshelf/bookshelf"
import Layout from "../layouts/layout"

const BookshelfPage = () => (
  <Layout title="Bookshelf">
    <h1 style={{ padding: 16 }}>Bookshelf</h1>
    <Bookshelf />
  </Layout>
)

export default BookshelfPage
